import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, DataGridTree } from '@miyagami-com/lsx-ui-components';
import {
  DataGridPremiumProps,
  GridSortModel,
  useGridApiRef,
} from '@mui/x-data-grid-premium';
import useColumns from './useColumns';
import useStyles from './useStyles';
import useGroupingColumn from './useGroupingColumn';
import useSportLine from './useSportLine';
import useRowTreeStyles from './useRowTreeStyles';
import useOnCellEditCommit from './useOnCellEditCommit';
import useBrandId from '../../../common/hooks/useBrandId';
import classNames from 'classnames';
import convertNodeToRows from './convertNodeToRows';
import { NavigationNode, RootState } from '../../../../types';
import DetailPanel from './DetailPanel';
import checkIsSystemAdmin from '../../../common/checkIsSystemAdmin';
import { useSelector } from 'react-redux';

const rowsPerPageOptions = [10, 15, 20, 25, 30, 35, 40, 45, 50, 100];
const rowHeight = 32;
const defaultPageSize = 100;

const sortModel: GridSortModel = [{ field: 'id', sort: 'asc' }];

interface SportLineProps
  extends Omit<DataGridPremiumProps, 'columns' | 'rows'> {
  playerId?: string;
}

const SportLine: React.FC<SportLineProps> = (props) => {
  const { playerId, ...otherProps } = props;

  const apiRef = useGridApiRef();
  const brandId = useBrandId();

  const rowClassName = useRowTreeStyles();
  const onCellEditCommit = useOnCellEditCommit({ brandId, playerId, apiRef });

  const { data: initialNode, isLoading } = useSportLine({
    brandId,
    playerId,
  });

  const { roles, uid: authUserId } = useSelector(
    (state: RootState) => state.user,
  );
  const isSystemAdmin = checkIsSystemAdmin({ roles });

  const [nodes, setNodes] = useState<NavigationNode[]>([]);

  useEffect(() => {
    setNodes([]);
  }, [brandId]);

  const addNode = useCallback((newNode: NavigationNode) => {
    setNodes((prev) => [...prev, newNode]);
  }, []);

  useEffect(() => {
    if (isLoading || !initialNode) return;

    addNode(initialNode);
  }, [addNode, initialNode, isLoading]);

  const rows = useMemo(() => {
    const rowsUnfiltered = convertNodeToRows({
      node: nodes,
      parentRow: null,
    });
    return rowsUnfiltered.filter((row) => {
      const disabledBy = isSystemAdmin ? 'systemAdmin' : authUserId;
      const shouldHideConfig =
        playerId != null &&
        row.playerId == null &&
        !row.isEnabled &&
        row.disabledBy === disabledBy;

      const disabledByHigherRole =
        !row.isEnabled && row.disabledBy !== authUserId;

      return (isSystemAdmin || !disabledByHigherRole) && !shouldHideConfig;
    });
  }, [nodes, playerId, authUserId, isSystemAdmin]);

  const [pageSize, setPageSize] = useState<number>(defaultPageSize);

  const onPageSizeChange = (newPageSize: number) => {
    setPageSize(newPageSize);
  };

  const classes = useStyles({});
  const groupingColDef = useGroupingColumn();

  const columns = useColumns();
  const className = classNames(classes.dataGridPadding);

  return (
    <Box height={1} display="flex">
      <Box flexGrow={1}>
        <DataGridTree
          {...otherProps}
          onCellEditCommit={onCellEditCommit}
          sortModel={sortModel}
          pageSize={pageSize}
          onPageSizeChange={onPageSizeChange}
          rowsPerPageOptions={rowsPerPageOptions}
          pagination
          autoHeight
          disableSelectionOnClick
          loading={isLoading}
          rows={rows || []}
          apiRef={apiRef}
          rowHeight={rowHeight}
          className={className}
          getDetailPanelContent={(params) => (
            <>
              <DetailPanel
                {...params}
                addNode={addNode}
                playerId={playerId}
                brandId={brandId}
              />
            </>
          )}
          getTreeDataPath={(row) => row?.path.split('/')}
          columns={columns}
          getDetailPanelHeight={() => rowHeight}
          groupingColDef={groupingColDef}
          getCellClassName={(params) => rowClassName(params.row.path)}
          getRowClassName={(params) => rowClassName(params.row.path)}
        />
      </Box>
    </Box>
  );
};

export default SportLine;
